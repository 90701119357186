import { mapGetters, mapMutations } from 'vuex';
import _laxios from '../../../store/_laxios';
import OneNotification from './OneNotification/index.vue';
import Pagination from '../../Common/Pagination/index.vue';

const { get, set } = require('../../../helpers/cookie');

const EXPIRES = 2592000;

Array.prototype.remove = function () {
  let what; const a = arguments; let L = a.length; let ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

export default {
  name: 'Notifications',
  data() {
    return {
      type: '',
      caregoryId: '',
      notifications: {},
      page: 1,
      limit: _TARGET_ === 'client' ? (Number(get('cabinet_ipp_notification')) || 10) : 10,
      currentPage: [],
      emptyPage: false,
      disabledRemoveBtn: false,
      checkedAll: false,
      massActionCheckedObj: [],
      massActionSelect: 0,
      selectValid: true,
      selectDisabled: true,
      preloader: false,
      isArchive: false,
    };
  },
  components: {
    'one-notification': OneNotification,
    pagination: Pagination,
  },
  init({ store, context }) {
    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }
    return store.dispatch('cabinet/notifications/counters', cookies);
  },
  created() {
    this.type = this.$route.meta.type;
    this.categoryId = this.$route.meta.categoryId;
  },
  watch: {
    $route(to) {
      this.emptyPage = false;
      this.page = 1;
      this.type = to.meta.type;
      this.categoryId = to.meta.categoryId;
      this.resetToDefault();
    },
  },
  mounted() {
    this.setPage(this.page, this.$route.meta.categoryId, this.limit);
  },
  methods: {
    resetToDefault() {
      this.massActionCheckedObj = [];
      this.checkedAll = false;
      // коли видаляють все з останньої сторніки, зміщуємось на сторінку назад
      if (this.pages < this.page && this.page > 1) {
        this.page--;
      }
      this.setPage(this.page, this.$route.meta.categoryId, this.limit);
    },
    getCategoryCounter(category) {
      return this.counters?.categories?.[category];
    },
    onPreloaderOn() {
      this.preloader = true;
    },
    /**
     * Метод який трігириться в компоненті пагінації
     * на зміну кількості сповіщень в видачі
     * @param limit
     */
    onChangeLimit(limit) {
      this.page = 1;
      this.limit = limit;
      set('cabinet_ipp_notification', limit, { expires: EXPIRES });
      this.resetToDefault();
    },
    /**
     * Метод який трігириться в компоненті пагінації
     * перехід по сторінкам
     * @param page
     */
    onChangePage(page) {
      this.page = page;
      this.resetToDefault();
    },
    /**
     * Метод який трігириться в дочірній компоненті OneNotification
     * Коли сповіщення видаляється кнопкою хрестик
     * @param id
     */
    onRemoveNotification(id) {
      this.incrementDeletedCnt();
      this.reloadMainPage();
      this.disabledRemoveBtn = true;
      this.notifications = {};
      this.resetToDefault();
      setTimeout(() => {
        this.disabledRemoveBtn = false;
        this.preloader = false;
      }, 500);
    },
    /**
     * Масові дії
     * Метод який трігириться в дочірній компоненті OneNotification
     * Додає в обєкт відмічені сповіщення (або видаляє)
     * @param id - унікальний ідентифікатор
     * @param check - відмітка
     */
    onChangeCheckbox(id, check) {
      if (check) {
        this.massActionCheckedObj.push(id);
      } else {
        this.massActionCheckedObj.remove(id);
      }
      this.selectDisabled = !Object.keys(this.massActionCheckedObj).length;
      this.massActionSelect = !Object.keys(this.massActionCheckedObj).length ? 0 : this.massActionSelect;
    },
    /**
     * Метод для масових дій
     * Кнопка "застосувати"
     */
    massActionSubmit() {
      if (Number(this.massActionSelect)) {
        if (!this.selectDisabled) {
          switch (Number(this.massActionSelect)) {
            case 1:
              this.massActionViewed();
              break;
            case 2:
              this.massActionRemove();
              break;
          }
        }
      } else {
        this.selectValid = false;
        setTimeout(() => {
          this.selectValid = true;
        }, 1500);
      }
    },
    /**
     * Метод для масових дій
     * "удалить в архив"
     */
    massActionRemove() {
      this.preloader = true;
      this.reloadMainPage();
      // при видаленні робимо повідомлення прочитаним також
      const data = {
        values: {
          is_viewed: true,
          is_deleted: true,
        },
        filter: {
          nc_notification_id: [],
        },
      };

      this.currentPage.forEach((oneItem) => {
        if (this.massActionCheckedObj.includes(oneItem.nc_notification_id)) {
          data.filter.nc_notification_id.push(oneItem.nc_notification_id);
          this.incrementDeletedCnt();
          oneItem.is_deleted = true;

          // віднімаємо лічильники
          this.deleteNotification({ categoryId: oneItem.notification_category, isViewed: oneItem.is_viewed });
        }
      });

      // відправляємо запит на notification про видалення
      _laxios.notifications_PATCH
        .request({ data }).then(() => {
          this.notifications = {};
          this.resetToDefault();
          this.preloader = false;
        }).catch((e) => {
          console.error('notifications_PATCH', e.message);
        });
    },
    /**
     * Метод для масових дій
     * "отметить как прочитаное"
     */
    massActionViewed() {
      const data = {
        values: {
          is_viewed: true,
        },
        filter: {
          nc_notification_id: [],
        },
      };

      this.currentPage.forEach((oneItem) => {
        if (this.massActionCheckedObj.includes(oneItem.nc_notification_id) && !oneItem.is_viewed) {
          data.filter.nc_notification_id.push(oneItem.nc_notification_id);
          oneItem.is_viewed = true;

          this.watchNotification({ categoryId: oneItem.notification_category });
        }
      });

      // відправляємо запит на notification про перегляд
      _laxios.notifications_PATCH
        .request({ data }).then(() => {
          this.checkedAll = false;
          this.setPage(this.page, this.$route.meta.categoryId, this.limit);
        }).catch((e) => {
          console.error('notifications_PATCH', e.message);
        });
    },
    activeCount() {
      const promoNew = this.counters.categories.promoNew || 0;
      const sellNew = this.counters.categories.sellNew || 0;
      const buyNew = this.counters.categories.buyNew || 0;
      const favoritesNew = this.counters.categories.favoritesNew || 0;
      const infoNew = this.counters.categories.infoNew || 0;

      const promoViewed = this.counters.categories.promoViewed || 0;
      const sellViewed = this.counters.categories.sellViewed || 0;
      const buyViewed = this.counters.categories.buyViewed || 0;
      const favoritesViewed = this.counters.categories.favoritesViewed || 0;
      const infoViewed = this.counters.categories.infoViewed || 0;

      const all = promoNew + sellNew + buyNew + favoritesNew + infoNew + promoViewed + sellViewed + buyViewed + favoritesViewed + infoViewed;

      switch (this.$route.meta.categoryId) {
        case 1:
          return this.limit < (promoNew + promoViewed) ? this.limit : (promoNew + promoViewed);
        case 2:
          return this.limit < (sellNew + sellViewed) ? this.limit : (sellNew + sellViewed);
        case 3:
          return this.limit < (buyNew + buyViewed) ? this.limit : (buyNew + buyViewed);
        case 4:
          return this.limit < (favoritesNew + favoritesViewed) ? this.limit : (favoritesNew + favoritesViewed);
        case 5:
          return this.limit < (infoNew + infoViewed) ? this.limit : (infoNew + infoViewed);
        default:
          return this.limit < all ? this.limit : all;
      }
    },
    /**
     * Сетапить сторінку сповіщень відносно отриманих параметрів
     * @param page - порядковий номер сторінки, яку потрібно відмалювати
     * @param categoryId - категорія нотифікації
     * @param limit - ліміт
     */
    setPage(page, categoryId, limit = 10) {
      const key = `page${page}${categoryId ? (`category${categoryId}`) : ''}limit${limit}`;
      if (!this.notifications[key] || !this.notifications[key].length) {
        const data = {
          skip: page * limit - limit,
          limit,
          sort: {
            add_date: -1,
          },
          filter: {
            is_deleted: this.$route.meta.typeId === 4,
            ...(categoryId !== 99 && { notification_category: categoryId }),
          },
        };

        _laxios.notificationsSearch
          .request({ data })
          .then((payload) => {
            this.notifications[key] = payload;
            this.currentPage = payload;
            if (!payload.length) {
              this.emptyPage = true;
            }
          }).catch((e) => {
            console.error('notificationsSearch', e.message);
          });
      } else {
        this.currentPage = this.notifications[key];
      }
    },
    /**
     * Мутації для лічильників
     */
    ...mapMutations({
      incrementDeletedCnt: 'cabinet/notifications/incrementDeletedCnt',
      watchNotification: 'cabinet/notifications/watchNotification',
      deleteNotification: 'cabinet/notifications/deleteNotification',
      reloadMainPage: 'cabinet/notifications/reloadMainPage',
    }),
  },
  computed: {
    ...mapGetters({
      counters: 'cabinet/notifications/counters',
      notificationCounters: 'cabinet/leftBar/notificationCounters',
      langId: 'lang/id',
    }),
    pages() {
      if (this.counters && this.$route.meta.typeId !== 4) {
        const promoNew = this._result(this.counters, 'categories.promoNew') || 0;
        const promoViewed = this._result(this.counters, 'categories.promoViewed') || 0;
        const sellNew = this._result(this.counters, 'categories.sellNew') || 0;
        const sellViewed = this._result(this.counters, 'categories.sellViewed') || 0;
        const buyNew = this._result(this.counters, 'categories.buyNew') || 0;
        const buyViewed = this._result(this.counters, 'categories.buyViewed') || 0;
        const favoritesNew = this._result(this.counters, 'categories.favoritesNew') || 0;
        const favoritesViewed = this._result(this.counters, 'categories.favoritesViewed') || 0;
        const infoNew = this._result(this.counters, 'categories.infoNew') || 0;
        const infoViewed = this._result(this.counters, 'categories.infoViewed') || 0;

        switch (this.$route.meta.categoryId) {
          case 1:
            return Math.ceil((promoNew + promoViewed) / this.limit);
          case 2:
            return Math.ceil((sellNew + sellViewed) / this.limit);
          case 3:
            return Math.ceil((buyNew + buyViewed) / this.limit);
          case 4:
            return Math.ceil((favoritesNew + favoritesViewed) / this.limit);
          case 5:
            return Math.ceil((infoNew + infoViewed) / this.limit);
          default:
            return Math.ceil((promoNew + promoViewed + sellNew + sellViewed + buyNew + buyViewed + favoritesNew + favoritesViewed + infoNew + infoViewed) / this.limit);
        }
      } else if (this.counters && this.$route.meta.typeId === 4) {
        return Math.ceil((this.counters.deleted) / this.limit);
      }
    },
  },

  i18n: {
    messages: {
      uk: {
        уведомлений: 'сповіщень',
        'Сейчас у Вас нет': 'Зараз у Вас немає',
        экстренных: 'екстренних',
        важных: 'важливих',
        информирующих: 'інформуючих',
        Архив: 'Архів',
        Уведомления: 'Сповіщення',
        Все: 'Усі',
        Экстренные: 'Екстрені',
        Важные: 'Важливі',
        Информирующие: 'Інформаційні',
        'Вернуться к активным уведомлениям': 'Повернутися до активних сповіщень',
        'Выделить все': 'Виділити все',
        'Выберите действие': 'Виберіть дію',
        'Отметить как прочитаное': 'Помітити як прочитане',
        'Удалить в архив': 'Видалити в архів',
        Применить: 'Застосувати',
        Налаштувати: 'Налаштувати',
        Акції: 'Акції',
        Продаж: 'Продаж',
        Купівля: 'Купівля',
        Обране: 'Обране',
        Інформаційні: 'Інформаційні',
      },
      ru: {
        уведомлений: 'уведомлений',
        'Сейчас у Вас нет': 'Сейчас у Вас нет',
        экстренных: 'экстренных',
        важных: 'важных',
        информирующих: 'информирующих',
        Архив: 'Архив',
        Уведомления: 'Уведомления',
        Все: 'Все',
        Экстренные: 'Экстренные',
        Важные: 'Важные',
        Информирующие: 'Информирующие',
        'Вернуться к активным уведомлениям': 'Вернуться к активным уведомлениям',
        'Выделить все': 'Выделить все',
        'Выберите действие': 'Выберите действие',
        'Отметить как прочитаное': 'Отметить как прочитаное',
        'Удалить в архив': 'Удалить в архив',
        Применить: 'Применить',
        Налаштувати: 'Настроить',
        Акції: 'Акції',
        Продаж: 'Продажа',
        Купівля: 'Покупка',
        Обране: 'Избранное',
        Інформаційні: 'Информационные',
      },
    },
  },
};
